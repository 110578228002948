/* === Brand Style Imports === */
@import url('cng/cng.css');
@import url('gpg/gpg.css');
@import url('igc/igc.css');
@import url('mdu/mdu.css');

:root {
    --bs-font-sans-serif: 'Segoe UI','Helvetica Neue', Helvetica, Arial, sans-serif !important;
    --footer-height: 290px;
    --margin-above-footer: 50px;
}

#root {
    /* making room for footer */
    margin-bottom: calc(var(--footer-height) + var(--margin-above-footer)); 
}

html {
    position: relative !important;
    min-height: 100% !important; 
}

html,
button,
input,
select,
textarea {
    color: #212529;
}

body {
    font-family: var(--bs-font-sans-serif) !important;
    margin: 0 0 100px !important;
}

.space-below {
    margin-bottom: 20px;
}

/* === Bootstrap Overrides === */

/* === GLOBAL === */
.alert-default {
    border-color: #ccc;
}

h3 {
    color: #466484;
}

h3#page-info-heading {
    margin: 0.5rem 0;
}

h4.tag {
    padding-bottom: 0.5rem;
}

.navbar-brand>img {
    Top: -1px;
    Left: 0;
    margin-right: 0.625rem;
    padding: 1px 2px 3px 2px;
    width: 28px;
    height: 28px;
    background-color: #fff;
    border-radius: 50%;
}

#data-display-div {
    margin: 2rem 0 0 0;
}

#sort-tags-btn,
.data-view-btn {
    margin-top: 0;
    *border: 1px solid #ccc;
    /* IE7 hack */
}

.data-view-btn.btn-primary {
    *border: 1px solid #006DCC;
    /* IE7 hack */
}

.data-view-btn .icon {
    padding-right: 0.5rem;
}

.wrapper {
    padding-top: 60px;
    /* adjusted for fixed-top navbar */
}

.banner {
    background-color: #e6f5ff;
}

.banner .card {
    background-color: inherit;
    border: none;
}

#main-content {
    position: relative;
}

.maincontainer {
    /* padding-bottom: 120px; */
}

.mfamainContainer {
    padding-bottom: 40 px !important;
    height: 90% !important;
}

/* ---------- main menu  ---------- */
/* .navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    background-color: #007bff;
    border-radius: 0.25rem;
} */

.nav-link {
    padding: 0.31rem 1rem;
}

.nav-item {
    margin-left: 1rem;
}

/* ---------- data view buttons ---------- */

#sort-tags-btn {
    margin-left: 0;
    margin-top: 10px;
}

#data-view-header,
#data-view-btn-group {
    margin-top: 10px;
    margin-right: 5px;
    z-index: 0;
}

/* ---------- legend button and table ---------- */
#legend-table {
    position: relative;
    margin: 1rem 0;
}

#legend-icon {
    margin-left: 1rem;
    zoom: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
    opacity: 0.7;
}

#legend-btn:hover>#legend-icon {
    zoom: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    -moz-opacity: 0.9;
    -khtml-opacity: 0.9;
    opacity: 0.9;
}

/* ------ grid (block) style data ----- */

#grid-view-container .row {
    margin-left: 0;
}

.grid-block {
    /* min-width: 270px;
    max-width: 380px; */
    text-shadow: none;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 1px 2px 8px 2px #ddd;
    box-shadow: 1px 2px 8px 2px #ddd;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

.smaller {
    font-size: 0.925rem;
}

.larger {
    font-size: 1.125rem;
}

.box-shadow {
    -webkit-box-shadow: 0 3px 5px #bbb;
    -moz-box-shadow: 0 3px 5px #bbb;
    box-shadow: 0 3px 5px #bbb;
}

.strong {
    font-weight: bold;
}

.red {
    color: red;
}

.create-request-modal-width {
    width: 75% !important;
    max-width: none!important;
    margin-right: auto!important;
    margin-left: auto!important;
}

.uppercase {
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
    .create-request-modal-width {
        width: 95% !important;
        max-width: none!important;
        margin-right: auto!important;
        margin-left: auto!important;
    }
}

.noPadding {
    padding: 0px !important;
}

.login,
.image {
    min-height: 35vh;
}

.bg-image {
    /* background-image: url('/images/Utility_Group_logo.png'); */
    background-size: 35vw;
    background-repeat: no-repeat;
    background-position: center center;
}

.dashboardHeading {
    text-decoration: none !important;
    padding-left: 20px;
}

.dashboardHeadingColor,
.footerTextColor {
    color: white !important;
}

.dashboard-header-name {
    color: white !important;
    margin-top: auto;
    margin-bottom: auto;
}

.newRequestButton,
.floatButton {
    float: right;
}

.dashboardPosition {
    padding: 1.3rem;
    width: 100%;
}

.loadingSpinner {
    padding: 30px;
    text-align: center;
}

.showSpinner {
    display: block;
}

.hideSpinner {
    display: none;
}

.profileText {
    text-decoration: none;
    margin: 0 10px;
}

.profileButton {
    float: right;
    margin: 0px 2px;
}

.profileDashboardButton {
    display: "inline";
    margin: 10px 0px;
}

.customBorder {
    padding: 10px;
}

.hrLine {
    border-top: 3px solid orange;
    margin-bottom: 15px !important;
}

.trigger-btn {
    display: inline-block;
    margin: 100px auto;
}

.defaultProfileColor {
    background-color: aqua !important;
}

.paddingNull {
    padding: 0 !important;
}

.bg-brand {
    background-color: var(--brand-color-base) !important; 
}

.bg-brand-secondary {
    background-color: var(--brand-color-hover) !important;
}

.csspopup .overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
}

.csspopup .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
}

.csspopup .popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}

.csspopup .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.csspopup .popup .close:hover {
    cursor: pointer;
    color: #000;
}

.csspopup .popup .content {
    max-height: 30%;
    overflow: auto;
}

@media screen and (max-width: 700px) {
    .csspopup .popup {
        width: 70%;
    }
}


/* Map */
.pac-container {
    /* fix issue where autocomplete popup not showing over modal */
    z-index: 10000 !important;
}

#title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}

.questionIcon {
    font-size: 10px;
    margin-left: 5px;
    border: 1px solid #aaa;
    display: inline-block;
    width: 16px;
    text-align: center;
    border-radius: 30px;
    height: 16px;
    position: relative;
    top: -4px;
    cursor: pointer;
}

.fade.show.popover {
    padding: 10px;
}

.invalid-feedback-check {
    width: 100% !important;
    margin-top: -0.75rem !important;
    margin-left: 12px !important;
    font-size: .875em;
    color: #dc3545
}

footer {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-top: var(--margin-above-footer);
    max-height: var(--footer-height);
    width: 100%;
    background-color: #000;
}

.footer-icons {
    color: #fff;
}

.footer-icons img~img {
    margin-left: 10px;
}

/* Buttons */
.btn {
    font-weight: bold !important;
    
}

.btn-primary {
    color: #fff;
    background-color: #344b63;
    border-color: #223242;
}

.btn-default {
    color: #344b63 !important;
    background-color: #fff !important;
    border-color: #344b63 !important;
}

.btn-default:hover {
    color: #fff !important;
    background-color: #344b63 !important;
    transition: 0.3s;
}

.btn-warning {
    color: #fff !important;
    background-color: #eea236;
    border-color: #df8a13;
}

.navbar-btn {
    padding-right: 0.7em;
}

.btn-register {
    margin-top: 7px;
    margin-bottom: 7px;
}

/* Forms */
.well-form {
    margin-top: 10px;
    padding: 19px;
    color: #151515;
}

.section-gray .well-form,
.section-colored .well-form {
	border: 1px solid #e3e3e3;
	background-color: #fff;
}

.well-form, .list-group-item {
	border: 1px solid #f1f5f6;
	background-color: #f5f5f5;
	background: -webkit-linear-gradient(top, #f9f9f9 10%, #f0f0f0 90%);
	background: linear-gradient(to bottom, #f9f9f9 10%, #f0f0f0 90%);
}

/* Sizing */
.w-30 {
    width: 30% !important;
}

.w-40 {
    width: 40% !important;
}

.img-logo {
    max-height: 140px;
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.spin {
    animation: fullRotate infinite .9s linear;
}

@keyframes fullRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.saveTimeStamp {
    font-style: italic;
    font-size: small;
    text-align: left;
}

@media only screen {
    .dynamic-vertical-padding {
        padding-top: 60px;
        padding-bottom: 40px;
    }
}

.overflowFix {
    overflow: auto;
}

hr.thicker {
    border: 3px solid ;
    border-radius: 5px;
}

.text-branded {
    color: var(--brand-color-base);
}

.div-overflow-fix {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
}

.label-overflow-fix {
    overflow-wrap: break-word;
}

.make-me-look-like-a-link {
    cursor:pointer;
    text-decoration:underline;
}

/* Fix issue where react-bootstrap dropdown button groups in a responseive table add scroll bars to the table instead of just rendering on top */
.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .btn-group-vertical {
    position: static;
}

.ellipsisOverflow {
    text-overflow: ellipsis;
    overflow: hidden;
}
